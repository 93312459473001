import { useEffect, useState } from 'react';
import { LogDebug } from 'utils/logging';

/**
 * @summary This function will encode known bad query params from the url
 */
const loggedKeys = {};
export function checkQueryParams(search) {
  try {
    decodeURI(search);
  } catch (e) {
    if (!loggedKeys[search]) {
      LogDebug('Decoding query param failed', {
        description: search,
      });
      loggedKeys[search] = true;
    }
  }

  return search;
}

/**
 * @summary use this hook to append query params to a link
 * @param {String} linkUrl - any url
 * @param {Object} queryParams - more params to send
 */
export default function useAppendQueryParams(linkUrl, queryParams = {}) {
  const [link, setLink] = useState(linkUrl);

  const queryParamsKey = Object.values(queryParams).join('_');

  useEffect(() => {
    let newLink = '';
    // Not tapping into router.query because it has query from slug that we dont want
    let moreParams = checkQueryParams(window.location.search);

    // Remove the ? from the query string as it can be in the existing url
    if (moreParams && linkUrl) {
      const linkHasParams = linkUrl.includes('?');
      moreParams = linkHasParams ? moreParams.replace(/\?/, '&') : moreParams;

      newLink = `${linkUrl}${moreParams}`;
    } else {
      newLink = linkUrl;
    }

    const additionalParams = Object.keys(queryParams)
      .map((key) => `${key}=${queryParams[key]}`)
      .join('&');

    // if there are additional params, add them to the link
    if (additionalParams.length && linkUrl) {
      newLink = newLink.includes('?')
        ? `${newLink}&${additionalParams}`
        : `${newLink}?${additionalParams}`;
    }

    setLink(newLink);
  }, [linkUrl, queryParamsKey]);

  return link;
}
