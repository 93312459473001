import React from 'react';
import PropTypes from 'prop-types';
import { QUERY_PARAMS } from 'consts';
import useAppendQueryParams from 'hooks/custom/useAppendQueryParams';
import ResultCardLink from './ResultCardLink';

const { IMPRESSION_GUID_PARAM } = QUERY_PARAMS;

function ResultCardTitle({
  children,
  className,
  linkUrl,
  onClick,
  impressionGuid,
  isProgramView,
}) {
  const linkWithParams = useAppendQueryParams(linkUrl, {
    [IMPRESSION_GUID_PARAM]: impressionGuid,
    click_location: isProgramView
      ? 'best matching card | schoolname'
      : 'school card | schoolname',
  });
  return (
    <ResultCardLink
      className={className}
      linkUrl={linkWithParams}
      onClick={onClick}
    >
      <h1>{children}</h1>
    </ResultCardLink>
  );
}

ResultCardTitle.propTypes = {
  onClick: PropTypes.func,
  linkUrl: PropTypes.node,
  impressionGuid: PropTypes.string,
  isProgramView: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default ResultCardTitle;
