import React from 'react';
import PropTypes from 'prop-types';
import cx from 'utils/classnames';
import Rating from 'components/base/rating/Rating';
import useAppendQueryParams from 'hooks/custom/useAppendQueryParams';
import { QUERY_PARAMS, RATING_TOOLTIP_TEXT } from 'consts';
import camelCase from 'lodash/camelCase';
import ResultCardInfoRow from './ResultCardInfoRow';
import ResultCardLogo from './ResultCardLogo';
import ResultCardLink from './ResultCardLink';
import ResultCardTitle from './ResultCardTitle';

const { IMPRESSION_GUID_PARAM } = QUERY_PARAMS;

function ResultCardBody({
  description,
  selectedProgramName,
  id,
  isLoading,
  isMobile,
  isProgramView,
  learningType,
  logoUrl,
  name,
  onCTAclick,
  onProgramLinkClick,
  onTitleClick,
  cardLink,
  onLogoClick,
  rating,
  impressionGuid,
}) {
  const learnMoreLink = useAppendQueryParams(cardLink, {
    [IMPRESSION_GUID_PARAM]: impressionGuid,
    click_location: isProgramView
      ? 'best matching card | learn-more'
      : 'school card | learn-more',
  });

  const bestMatchingProgramLink = useAppendQueryParams(cardLink, {
    [IMPRESSION_GUID_PARAM]: impressionGuid,
    click_location: 'best matching card | best matching link',
  });

  return (
    <div className="resultCard__info">
      <div
        className={cx('resultCard__schoolInfo', {
          resultCard__logoSection: !isMobile,
        })}
      >
        {!isMobile && (
          <div className="resultCard__schoolLogo">
            <ResultCardLogo
              isLoading={isLoading}
              linkUrl={cardLink}
              logoUrl={logoUrl}
              onClick={onLogoClick}
              impressionGuid={impressionGuid}
              isProgramView={isProgramView}
            />
          </div>
        )}
        {isMobile && (
          <ResultCardInfoRow
            isLoading={isLoading}
            label="Editor's Rating"
            toolTip={{
              label: 'More Info',
              text: RATING_TOOLTIP_TEXT,
            }}
            value={isLoading ? '' : <Rating rating={rating} id={id} />}
            className="hasRating"
          />
        )}
        {isProgramView && isMobile && (
          <ResultCardInfoRow
            isLoading={isLoading}
            label={
              isMobile ? 'Best Matching Program:' : 'Best Matching Program'
            }
            className="hasProgram"
            value={
              <ResultCardLink
                linkUrl={bestMatchingProgramLink}
                target="_blank"
                rel="noreferrer"
                onClick={onProgramLinkClick}
              >
                {selectedProgramName}
              </ResultCardLink>
            }
          />
        )}
        <ResultCardLink
          linkUrl={learnMoreLink}
          target="_blank"
          rel="noreferrer"
          className="resultCard__cta"
          onClick={onCTAclick}
        >
          {isProgramView ? 'Visit Site' : 'Find Your Program'}
        </ResultCardLink>
      </div>

      <div className="resultCard__details">
        {!isMobile && (
          <ResultCardTitle
            className="resultCard__title"
            impressionGuid={impressionGuid}
            isProgramView={isProgramView}
            linkUrl={cardLink}
            onClick={onTitleClick}
          >
            {name}
          </ResultCardTitle>
        )}
        <div className="resultCard__meta">
          {!isMobile && (
            <ResultCardInfoRow
              isLoading={isLoading}
              className="hasRating"
              label="Editor's Rating"
              toolTip={{
                label: 'More Info',
                text: RATING_TOOLTIP_TEXT,
              }}
              value={isLoading ? '' : <Rating rating={rating} id={id} />}
            />
          )}
          {!isMobile && (
            <ResultCardInfoRow
              isLoading={isLoading}
              label="Format"
              value={learningType}
              className={`hasFormat resultCard__infoRow--${camelCase(
                learningType
              )}`}
            />
          )}
          {isProgramView && !isMobile && (
            <ResultCardInfoRow
              isLoading={isLoading}
              label={
                isMobile ? 'Best Matching Program:' : 'Best Matching Program'
              }
              className="hasProgram"
              value={
                <ResultCardLink
                  linkUrl={bestMatchingProgramLink}
                  target="_blank"
                  rel="noreferrer"
                  onClick={onProgramLinkClick}
                >
                  {selectedProgramName}
                </ResultCardLink>
              }
            />
          )}
          {!isMobile && (
            <ResultCardInfoRow
              isLoading={isLoading}
              label={isProgramView ? 'Program Description' : 'Description'}
              value={description}
              className="hasDescription"
            />
          )}
        </div>
      </div>
    </div>
  );
}

ResultCardBody.propTypes = {
  cardLink: PropTypes.node,
  description: PropTypes.string,
  id: PropTypes.string,
  impressionGuid: PropTypes.string,
  isLoading: PropTypes.bool,
  isMobile: PropTypes.bool,
  isProgramView: PropTypes.bool,
  learningType: PropTypes.string,
  logoUrl: PropTypes.string,
  name: PropTypes.string,
  onCTAclick: PropTypes.func,
  onLogoClick: PropTypes.func,
  onProgramLinkClick: PropTypes.func,
  onTitleClick: PropTypes.func,
  rating: PropTypes.number,
  selectedProgramName: PropTypes.string,
};

export default ResultCardBody;
