import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { QUERY_PARAMS } from 'consts';
import useAppendQueryParams from 'hooks/custom/useAppendQueryParams';
import ResultCardLink from './ResultCardLink';
import ResultCardContext from './ResultCardContext';

const { IMPRESSION_GUID_PARAM } = QUERY_PARAMS;

function ResultCardLogo({
  isLoading,
  linkUrl,
  logoUrl,
  onClick,
  impressionGuid,
  isProgramView,
}) {
  const { index } = useContext(ResultCardContext);
  const linkWithParams = useAppendQueryParams(linkUrl, {
    [IMPRESSION_GUID_PARAM]: impressionGuid,
    click_location: isProgramView
      ? 'best matching card | logo'
      : 'school card | logo',
  });

  if (isLoading) {
    return null;
  }

  return (
    <ResultCardLink
      linkUrl={linkWithParams}
      className="resultCard__imgLink"
      onClick={onClick}
    >
      <img
        src={logoUrl}
        alt="logo"
        loading={index > 2 ? 'lazy' : 'eager'}
        className="resultCard__img"
        height="90"
        width="300"
      />
    </ResultCardLink>
  );
}

ResultCardLogo.propTypes = {
  isLoading: PropTypes.bool,
  logoUrl: PropTypes.string,
  onClick: PropTypes.func,
  linkUrl: PropTypes.node,
  impressionGuid: PropTypes.string,
  isProgramView: PropTypes.bool,
};

export default ResultCardLogo;
