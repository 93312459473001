import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ToolTip from 'components/base/toolTip/ToolTip';
import Wysiwyg from 'components/blocks/wysiwyg/Wysiwyg';
import cx from 'utils/classnames';
import GlobalContext from 'hooks/contexts/GlobalContext';

function ResultCardInfoRow({ label, value, className, toolTip }) {
  const {
    windowSize: { currentBreakpoint },
  } = useContext(GlobalContext);

  const toolTipPositioning = {
    mobile: 'bottom',
    tablet: 'bottom',
    desktop: 'bottom',
  };

  return (
    <div
      className={cx('resultCard__infoRow', {
        [`resultCard__infoRow--${className}`]: !!className,
      })}
    >
      {!toolTip && <span className="resultCard__metaLabel">{label}</span>}
      {toolTip && (
        <div className="resultCard__metaLabel">
          <div className="resultCard__metaLabelInner">
            <span className="resultCard__metaLabelText">{label}</span>
            <div className="resultCard__metaLabelToolTip">
              <ToolTip
                label={toolTip.label}
                positioning={toolTipPositioning[currentBreakpoint]}
              >
                {toolTip.text}
              </ToolTip>
            </div>
          </div>
        </div>
      )}
      <div className="resultCard__metaValue">
        {typeof value === 'string' ? <Wysiwyg content={value} /> : value}
      </div>
    </div>
  );
}

ResultCardInfoRow.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.node,
  ]),
  className: PropTypes.string,
  toolTip: PropTypes.shape({
    label: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  }),
};

export default ResultCardInfoRow;
