import { trackClickPortalImpression } from 'app-requests/clickPortalSchoolManager';
import { useState, useEffect } from 'react';
import {
  trackClickPortalClickConversion,
  trackClickPortalListingAction,
} from 'utils/trackingFunctions';

export default function useListingResultTracking(
  listingResult,
  isBestMatching
) {
  const [state, setState] = useState({
    listingResult,
    isBestMatching,
  });

  useEffect(() => {
    setState({ listingResult, isBestMatching });
  }, [listingResult, isBestMatching]);

  /**
   * @summary On a click portal result card we want to track clicks and views. Clicks should go to only GA but views will go to both backend and GA4
   * @param {Boolean} options.isView - was the card viewed or clicked
   * @param {String} options.actionLocation - the location on the result card that this event was fired for
   * @param {Array} options.programGuids - the guids of the programs that were viewed
   * @see https://triadms.atlassian.net/wiki/spaces/NEWPLATFOR/pages/1887469569/Analytics+Requirements
   * @private
   */
  function _resultCardAction(options) {
    const { isView, actionLocation, programGuids } = options;

    // To GA
    trackClickPortalListingAction(
      isView,
      state.listingResult.impressionGuid,
      actionLocation
    );

    if (isView) {
      // To Triad Backend
      trackClickPortalImpression({
        programGuids,
        schoolId: state.listingResult.id,
        impressionGuid: state.listingResult.impressionGuid,
        viewLocation: actionLocation,
      });
    } else {
      // To GA
      trackClickPortalClickConversion(
        state.listingResult.revenue,
        state.listingResult.schoolCode
      );
    }
  }

  /**
   * @param {String} location - the location on the result card that this event was fired for
   * @param {Array} programGuids - the guids of the programs that were viewed
   * @private
   */
  function _schoolCardView(location, programGuids) {
    const actionLocation = `school card | ${location}`;

    _resultCardAction({
      isView: true,
      actionLocation,
      programGuids,
    });
  }

  /**
   * @param {String} location - the location on the result card that this event was fired for
   * @private
   */
  function _schoolCardClick(location) {
    const actionLocation = `school card | ${location}`;

    _resultCardAction({
      isView: false,
      actionLocation,
    });
  }

  /**
   * @param {String} location - the location on the result card that this event was fired for
   * @private
   */
  function _bestMatchCardView(location, programGuids) {
    const actionLocation = `best matching card | ${location}`;

    _resultCardAction({
      isView: true,
      actionLocation,
      programGuids,
    });
  }

  /**
   * @param {String} location - the location on the result card that this event was fired for
   * @private
   */
  function _bestMatchCardClick(location) {
    const actionLocation = `best matching card | ${location}`;

    _resultCardAction({
      isView: false,
      actionLocation,
    });
  }

  // -------------------------------------- //
  // ------------ VIEW ACTIONS ------------ //
  // -------------------------------------- //

  /**
   * @private
   */
  function _schoolCardViewed() {
    _schoolCardView('card');
  }

  /**
   * @private
   */
  function _schoolCardViewedHighlights() {
    _schoolCardView('highlights');
  }

  /**
   * @private
   */
  function _schoolCardViewedAllPrograms() {
    const programGuids = state.listingResult.otherPrograms
      .flatMap((group) => group.ProgramList)
      .map(({ value }) => value);
    _schoolCardView('all programs', programGuids);
  }

  /**
   * @private
   */
  function _bestMatchCardViewed() {
    _bestMatchCardView('card');
  }

  /**
   * @private
   */
  function _bestMatchCardViewedHighlights() {
    _bestMatchCardView('highlights');
  }

  /**
   * @private
   */
  function _bestMatchCardViewedAllPrograms() {
    const programGuids = state.listingResult.otherPrograms
      .flatMap((group) => group.ProgramList)
      .map(({ value }) => value);

    _bestMatchCardView('all programs', programGuids);
  }

  // -------------------------------------- //
  // ------------ CLICK ACTIONS ------------ //
  // -------------------------------------- //

  /**
   * @private
   */
  function _schoolCardClickedLogo() {
    _schoolCardClick('logo');
  }

  /**
   * @private
   */
  function _schoolCardClickedTitle() {
    _schoolCardClick('schoolname');
  }

  /**
   * @private
   */
  function _schoolCardClickedLearnMore() {
    _schoolCardClick('learn-more');
  }

  /**
   * @private
   */
  function _schoolCardClickedSeeAllPrograms() {
    _schoolCardClick('all programs | see all link');
  }

  /**
   * @private
   */
  function _schoolCardClickedSingleDegree() {
    _schoolCardClick('all programs | degree link');
  }

  /**
   * @private
   */
  function _bestMatchCardClickedLogo() {
    _bestMatchCardClick('logo');
  }

  /**
   * @private
   */
  function _bestMatchCardClickedTitle() {
    _bestMatchCardClick('schoolname');
  }

  /**
   * @private
   */
  function _bestMatchCardClickedLearnMore() {
    _bestMatchCardClick('learn-more');
  }

  /**
   * @private
   */
  function _bestMatchCardClickedSeeAllPrograms() {
    _bestMatchCardClick('all programs | see all link');
  }

  /**
   * @private
   */
  function _bestMatchCardClickedSingleProgram() {
    _bestMatchCardClick('all programs | program link');
  }

  /**
   * @private
   */
  function _bestMatchCardClickedProgramLink() {
    _bestMatchCardClick('best matching link');
  }

  // -------------------------------------- //
  // ------------ PUBLIC ACTIONS ------------ //
  // -------------------------------------- //

  /**
   * @public
   */
  function clickedSingle() {
    state.isBestMatching
      ? _bestMatchCardClickedSingleProgram()
      : _schoolCardClickedSingleDegree();
  }

  /**
   * @public
   */
  function clickedLogo() {
    state.isBestMatching
      ? _bestMatchCardClickedLogo()
      : _schoolCardClickedLogo();
  }

  /**
   * @public
   */
  function clickedTitle() {
    state.isBestMatching
      ? _bestMatchCardClickedTitle()
      : _schoolCardClickedTitle();
  }

  /**
   * @public
   */
  function clickedLearnMore() {
    state.isBestMatching
      ? _bestMatchCardClickedLearnMore()
      : _schoolCardClickedLearnMore();
  }

  /**
   * @public
   */
  function clickedProgramLink() {
    _bestMatchCardClickedProgramLink();
  }

  /**
   * @public
   */
  function clickedSeeAllPrograms() {
    state.isBestMatching
      ? _bestMatchCardClickedSeeAllPrograms()
      : _schoolCardClickedSeeAllPrograms();
  }

  /**
   * @public
   */
  function viewedAllPrograms() {
    state.isBestMatching
      ? _bestMatchCardViewedAllPrograms()
      : _schoolCardViewedAllPrograms();
  }

  /**
   * @public
   */
  function viewedHighlights() {
    state.isBestMatching
      ? _bestMatchCardViewedHighlights()
      : _schoolCardViewedHighlights();
  }

  /**
   * @public
   */
  function viewedResult() {
    state.isBestMatching ? _bestMatchCardViewed() : _schoolCardViewed();
  }

  return {
    clickedSingle,
    clickedLogo,
    clickedTitle,
    clickedLearnMore,
    clickedProgramLink,
    clickedSeeAllPrograms,
    viewedAllPrograms,
    viewedHighlights,
    viewedResult,
  };
}
