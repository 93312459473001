import React, { useContext } from 'react';
import get from 'lodash/get';
import GlobalContext from 'hooks/contexts/GlobalContext';
import cx from 'utils/classnames';
import getFormSelectionObjects from './utils/getFormSelectionObjects';

interface ClickPortalSearchHeaderProps {
  title?: string;
  subTitle?: string;
  showTitle: boolean;
}

function ClickPortalSearchHeader(
  props: ClickPortalSearchHeaderProps
): JSX.Element | null {
  const { title, subTitle, showTitle } = props;
  const {
    microSiteTaxonomyMap,
    clickPortal: { currentSelection, isLoading },
  } = useContext(GlobalContext);

  const { degree, category, subject } = getFormSelectionObjects(
    currentSelection,
    microSiteTaxonomyMap
  );

  const hasSelection = category && degree && subject;
  let _title = title;
  let _subTitle = subTitle;

  if (hasSelection) {
    _title = `${get(degree, 'label')}s in ${get(category, 'label')}`;
  }

  if (hasSelection) {
    _subTitle = 'Our Picks for Online Colleges with';
  }

  if (!showTitle) {
    return null;
  }
  return (
    <div
      className={cx('clickPortalSearchHeader', {
        'clickPortalSearchHeader--isLoading': isLoading,
      })}
    >
      {showTitle && (
        <h1 className="clickPortalSearchHeader__title">
          <span className="clickPortalSearchHeader__preamble">{_subTitle}</span>
          <span className="clickPortalSearchHeader__titleText">{_title}</span>
        </h1>
      )}
    </div>
  );
}

export default ClickPortalSearchHeader;
