import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import Accordion from 'components/base/accordion/Accordion';
import AccordionPanel from 'components/base/accordion/AccordionPanel';
import Wysiwyg from 'components/blocks/wysiwyg/Wysiwyg';
import GlobalContext from 'hooks/contexts/GlobalContext';
import getFormSelectionObjects from 'components/form-wizards/click-portal-search/utils/getFormSelectionObjects';
import { QUERY_PARAMS } from 'consts';
import useAppendQueryParams from 'hooks/custom/useAppendQueryParams';
import camelCase from 'lodash/camelCase';
import ResultCardProgram from './ResultCardProgram';
import ResultCardLink from './ResultCardLink';
import ResultCardContext from './ResultCardContext';

const { IMPRESSION_GUID_PARAM } = QUERY_PARAMS;

function ResultCardFooter({
  description,
  highlights,
  impressionGuid,
  isLoading,
  schoolId,
  isMobile,
  isProgramView,
  onTotalCountClick,
  otherPrograms,
  programCount,
  cardLink,
}) {
  const {
    microSiteTaxonomyMap,
    clickPortal: { currentSelection },
  } = useContext(GlobalContext);

  const { listingResultTracking, schoolName } = useContext(ResultCardContext);

  const seeAllLink = useAppendQueryParams(cardLink, {
    [IMPRESSION_GUID_PARAM]: impressionGuid,
    click_location: isProgramView
      ? 'best matching card | all programs | see all link'
      : 'school card | all programs | see all link',
  });

  const { degree, subject } = getFormSelectionObjects(
    currentSelection,
    microSiteTaxonomyMap
  );

  function getResultTotalsText() {
    if (isLoading) {
      return 'Loading...';
    }

    const totalText = `See all ${programCount} programs`;

    return `${totalText}`;
  }

  function trackAllProgramsViewed() {
    listingResultTracking.viewedAllPrograms();
  }

  function trackHighlightsViewed() {
    listingResultTracking.viewedHighlights();
  }

  function getPanelId(panelTitle) {
    return camelCase(`${schoolName}_${panelTitle}`);
  }

  return (
    <Accordion>
      {isMobile && (
        <AccordionPanel
          title={isProgramView ? 'Program Description' : 'Description'}
          id={getPanelId('description')}
        >
          <Wysiwyg content={description} />
        </AccordionPanel>
      )}
      <AccordionPanel
        title="School Highlights"
        onActive={trackHighlightsViewed}
        id={getPanelId('highlights')}
      >
        {/* TODO: Should this live in its own function? How do we want to structure this data? */}
        <Wysiwyg
          content={highlights && highlights.map((item) => item).join('')}
        />
      </AccordionPanel>
      <AccordionPanel
        title={degree && subject ? 'Best Matching Programs' : 'All Programs'}
        onActive={trackAllProgramsViewed}
        id={getPanelId('programs')}
      >
        <div className="resultList">
          <div className="resultList__groups">
            {otherPrograms &&
              otherPrograms.map((group) => (
                <div className="resultList__group" key={group.value}>
                  <div className="resultList__groupTitle">{group.label}</div>
                  <ul className="resultList__items">
                    {group.ProgramList &&
                      group.ProgramList.map((item) => (
                        <ResultCardProgram
                          key={item.label || item}
                          item={item}
                          impressionGuid={impressionGuid}
                          schoolId={schoolId}
                          isProgramView={isProgramView}
                        />
                      ))}
                  </ul>
                </div>
              ))}
          </div>
          {programCount > 1 && (
            <div className="resultList__ft">
              <ResultCardLink
                linkUrl={seeAllLink}
                className="resultList__link"
                onClick={onTotalCountClick}
              >
                {getResultTotalsText()}
              </ResultCardLink>
            </div>
          )}
        </div>
      </AccordionPanel>
    </Accordion>
  );
}

ResultCardFooter.propTypes = {
  description: PropTypes.string,
  highlights: PropTypes.arrayOf(PropTypes.string),
  isMobile: PropTypes.bool,
  isProgramView: PropTypes.bool,
  isLoading: PropTypes.bool,
  cardLink: PropTypes.string,
  impressionGuid: PropTypes.string,
  otherPrograms: PropTypes.arrayOf(
    PropTypes.shape({
      shortDesc: PropTypes.string,
      categoryPriority: PropTypes.number,
      DegreeTypeGuid: PropTypes.string,
      destinationUrl: PropTypes.string,
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ),
  programCount: PropTypes.number,
  schoolId: PropTypes.string,
  onTotalCountClick: PropTypes.func,
};

export default ResultCardFooter;
