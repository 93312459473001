import React from 'react';
import PropTypes from 'prop-types';
import ResultCardLogo from './ResultCardLogo';
import ResultCardTitle from './ResultCardTitle';

function ResultCardHeader({
  badge,
  isLoading,
  isMobile,
  logoUrl,
  name,
  onLogoClick,
  onTitleClick,
  cardLink,
  impressionGuid,
  isProgramView,
}) {
  return (
    <>
      {badge && <span className="resultCard__badge">{badge}</span>}
      {isMobile && (
        <>
          <div className="resultCard__schoolLogo">
            <ResultCardLogo
              isLoading={isLoading}
              linkUrl={cardLink}
              logoUrl={logoUrl}
              onClick={onLogoClick}
              impressionGuid={impressionGuid}
              isProgramView={isProgramView}
            />
          </div>
          <ResultCardTitle
            className="resultCard__title"
            impressionGuid={impressionGuid}
            isProgramView={isProgramView}
            linkUrl={cardLink}
            onClick={onTitleClick}
          >
            {name}
          </ResultCardTitle>
        </>
      )}
    </>
  );
}

ResultCardHeader.propTypes = {
  badge: PropTypes.string,
  isLoading: PropTypes.bool,
  isMobile: PropTypes.bool,
  logoUrl: PropTypes.string,
  name: PropTypes.string,
  onLogoClick: PropTypes.func,
  onTitleClick: PropTypes.func,
  cardLink: PropTypes.string,
  impressionGuid: PropTypes.string,
  isProgramView: PropTypes.bool,
};

export default ResultCardHeader;
