import camelCase from 'lodash/camelCase';

/* eslint-disable import/prefer-default-export */
export function getRatingObj(index, rating, id) {
  // strip off any decimals, so we know how many full icons we're dealing with
  const fullRatingCount = Math.floor(rating);
  const partialRatingValue = rating % Math.floor(rating);

  // this needs to be unique per rating since it's an id
  // TODO: find a better way to make each one unique on the page so that we never run the risk of duplicate ids on a page
  const ratingId = `gradient${partialRatingValue * 100}-${index}-${camelCase(
    id
  )}`;

  // defaults: empty icons
  let currentRating = {
    value: 0,
    fill: 'var(--ratingEmpty)',
    id: ratingId,
  };

  // full icons
  if (index <= fullRatingCount) {
    currentRating = {
      value: 100,
      fill: 'var(--ratingFull)',
      id: ratingId,
    };
  }

  // partial icons
  if (index === fullRatingCount + 1) {
    currentRating = {
      value: partialRatingValue * 100,
      fill: `url(#${ratingId})`,
      id: ratingId,
    };
  }
  return currentRating;
}
