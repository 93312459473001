/* eslint-disable react/jsx-no-target-blank */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import useAppendQueryParams from 'hooks/custom/useAppendQueryParams';
import { QUERY_PARAMS } from 'consts';
import ResultCardLink from './ResultCardLink';
import ResultCardContext from './ResultCardContext';

const { IMPRESSION_GUID_PARAM } = QUERY_PARAMS;

export default function ResultCardProgram({
  item,
  impressionGuid,
  isProgramView,
}) {
  const { listingResultTracking } = useContext(ResultCardContext);

  const link = useAppendQueryParams(item.destinationUrl, {
    [IMPRESSION_GUID_PARAM]: impressionGuid,
    click_location: isProgramView
      ? 'best matching card | all programs | program link'
      : 'school card | all programs | degree link',
  });

  const handleLinkClick = () => {
    listingResultTracking.clickedSingle();
  };

  return (
    <li className="resultList__item" key={item.label || item}>
      <ResultCardLink
        linkUrl={link}
        className="resultList__link"
        onClick={handleLinkClick}
      >
        {item.label}
      </ResultCardLink>
    </li>
  );
}

ResultCardProgram.propTypes = {
  impressionGuid: PropTypes.string,
  isProgramView: PropTypes.bool,
  item: PropTypes.shape({
    shortDesc: PropTypes.string,
    categoryPriority: PropTypes.number,
    DegreeTypeGuid: PropTypes.string,
    destinationUrl: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
  }),
};
