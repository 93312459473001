import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { QUERY_PARAMS } from 'consts';
import GlobalContext from 'hooks/contexts/GlobalContext';
import { trackUsersGoogleSessionIds } from 'app-requests/triadmsRequests';
import ResultCardContext from './ResultCardContext';

const { SHARED_SESSION_ID, ORIGIN_SCHOOL_CODE } = QUERY_PARAMS;

function ResultCardLink({ linkUrl, children, onClick, className }) {
  const { isOffsiteConversion } = useContext(ResultCardContext);
  const {
    sessionInfo: { userSessionId },
    siteMeta,
  } = useContext(GlobalContext);
  const [_linkUrl, setLinkUrl] = useState(linkUrl);

  const _onClick = (e) => {
    if (isOffsiteConversion) {
      trackUsersGoogleSessionIds();
    }
    onClick(e);
  };

  useEffect(() => {
    const additionalParams = [];

    if (userSessionId) {
      additionalParams.push(`${SHARED_SESSION_ID}=${userSessionId}`);
    }

    if (siteMeta?.schoolCode) {
      additionalParams.push(`${ORIGIN_SCHOOL_CODE}=${siteMeta?.schoolCode}`);
    }

    let operator = linkUrl?.includes('?') ? '&' : '?';
    operator = additionalParams.length ? operator : '';

    const updatedLink = `${linkUrl}${operator}${additionalParams.join('&')}`;
    setLinkUrl(updatedLink);
  }, [linkUrl, userSessionId, siteMeta?.schoolCode]);

  return (
    <a
      href={_linkUrl}
      className={className || 'resultCard__link'}
      target="_blank"
      rel="noreferrer"
      onClick={_onClick}
      data-testid="resultCardLink"
    >
      {children}
    </a>
  );
}

ResultCardLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
  ]),
  className: PropTypes.string,
  linkUrl: PropTypes.string,
  onClick: PropTypes.func,
};

export default ResultCardLink;
