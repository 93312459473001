import React, { useContext, useMemo } from 'react';
import GlobalContext from 'hooks/contexts/GlobalContext';

import { BREAKPOINT_MAP, FIELD_NAMES } from 'consts';
import ClickPortalResultsSection from './ClickPortalResultsSection';

const loadingData = [{ id: '1' }, { id: '2' }, { id: '3' }];

export default function ClickPortalResults() {
  const {
    clickPortal: { isLoading, results, currentSelection, hasTags },
    siteMeta: { disclosure },
    windowSize: { currentBreakpoint },
  } = useContext(GlobalContext);

  function isMatch(school) {
    if (hasTags) {
      return school?.filterTagName?.length;
    }

    const { matchingCategory } = school;
    const matchingConcentration =
      currentSelection[FIELD_NAMES.CLICK_PORTAL_CONCENTRATIONS]?.value;

    return !matchingConcentration || matchingCategory === matchingConcentration;
  }

  const groupedResults = useMemo(() => {
    if (isLoading) {
      return { matches: loadingData, nonMatches: loadingData };
    }
    const groups = results.reduce(
      (acc, school) => {
        isMatch(school)
          ? acc.matches.push(school)
          : acc.nonMatches.push(school);

        return acc;
      },
      { matches: [], nonMatches: [] }
    );
    return groups;
  }, [results, currentSelection, isLoading]);

  return (
    <>
      {groupedResults.matches.length > 0 && (
        <ClickPortalResultsSection
          results={groupedResults.matches}
          currentBreakpoint={currentBreakpoint}
          isLoading={isLoading}
          disclosure={disclosure}
        />
      )}
      {groupedResults.nonMatches.length > 0 && (
        <ClickPortalResultsSection
          results={groupedResults.nonMatches}
          resultType="nonMatches"
          currentBreakpoint={currentBreakpoint}
          isLoading={isLoading}
          title={
            currentBreakpoint === BREAKPOINT_MAP.DESKTOP.label
              ? `Other schools with programs you may be interested in:`
              : `Other programs you may be interested in:`
          }
        />
      )}
    </>
  );
}
