/* eslint-disable react/prop-types */
import React, { useContext, useRef, useEffect, useMemo } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import cx from 'utils/classnames';
import useOnScreen from 'hooks/custom/useOnScreen';
import GlobalContext from 'hooks/contexts/GlobalContext';
import ResultCardHeader from './ResultCardHeader';
import ResultCardBody from './ResultCardBody';
import ResultCardFooter from './ResultCardFooter';
import useListingResultTracking from './useListingResultTracking';
import ResultCardContext from './ResultCardContext';

export default function ResultCard({ schoolInfo, isLoading, index, badge }) {
  const ref = useRef();

  const {
    windowSize: { currentBreakpoint = 'mobile' },
  } = useContext(GlobalContext);

  const { hasBeenSeen } = useOnScreen(ref, '-200px');

  const isMobile = currentBreakpoint === 'mobile';

  const isProgramView = !isEmpty(schoolInfo.selectedProgram);

  const listingResultTracking = useListingResultTracking(
    schoolInfo,
    isProgramView
  );

  const handleAllProgramsClick = () => {
    listingResultTracking.clickedSeeAllPrograms();
  };

  const handleLogoClick = () => {
    listingResultTracking.clickedLogo();
  };

  const handleTitleClick = () => {
    listingResultTracking.clickedTitle();
  };

  const handleLearnMoreClick = () => {
    listingResultTracking.clickedLearnMore();
  };

  const handleProgramLinkClick = () => {
    listingResultTracking.clickedProgramLink();
  };

  const currentLink = isProgramView
    ? schoolInfo?.selectedProgram?.destinationUrl
    : schoolInfo?.url;

  useEffect(() => {
    if (hasBeenSeen) {
      listingResultTracking.viewedResult();
    }
  }, [hasBeenSeen]);

  const getSchoolLogoUrl = (imageMap = {}) => {
    return isMobile ? imageMap.mobile : imageMap.desktop;
  };

  const logoUrl = useMemo(
    () => getSchoolLogoUrl(schoolInfo.schoolLogoUrl),
    [schoolInfo]
  );

  return (
    <ResultCardContext.Provider
      value={{
        listingResultTracking,
        index,
        schoolName: schoolInfo?.name,
        isOffsiteConversion: schoolInfo?.isOffsiteConversion,
      }}
    >
      <article
        ref={ref}
        data-testid="resultCard"
        className={cx('resultCard', {
          'resultCard--isLoading': isLoading,
          resultCard__mobileContainer: isMobile,
        })}
      >
        <div className="resultCard__header">
          <ResultCardHeader
            isLoading={isLoading}
            isMobile={isMobile}
            logoUrl={logoUrl}
            name={schoolInfo.name}
            onLogoClick={handleLogoClick}
            onTitleClick={handleTitleClick}
            cardLink={currentLink}
            impressionGuid={schoolInfo.impressionGuid}
            isProgramView={isProgramView}
            badge={badge}
          />
        </div>
        <div className="resultCard__body">
          <ResultCardBody
            description={
              isProgramView
                ? get(schoolInfo, 'selectedProgram.shortDesc')
                : schoolInfo.description
            }
            name={schoolInfo.name}
            id={schoolInfo?.id?.toString()}
            isLoading={isLoading}
            isMobile={isMobile}
            isProgramView={isProgramView}
            learningType={schoolInfo.learningType}
            logoUrl={logoUrl}
            onCTAclick={handleLearnMoreClick}
            onLogoClick={handleLogoClick}
            onProgramLinkClick={handleProgramLinkClick}
            onTitleClick={handleTitleClick}
            cardLink={currentLink}
            rating={schoolInfo.rating}
            selectedProgramName={get(schoolInfo, 'selectedProgram.label')}
            impressionGuid={schoolInfo.impressionGuid}
          />
        </div>

        <div className="resultCard__footer">
          <ResultCardFooter
            description={
              isProgramView
                ? get(schoolInfo, 'selectedProgram.shortDesc')
                : schoolInfo.description
            }
            highlights={schoolInfo.highlights}
            isLoading={isLoading}
            isMobile={isMobile}
            isProgramView={isProgramView}
            onTotalCountClick={handleAllProgramsClick}
            otherPrograms={schoolInfo.otherPrograms}
            programCount={schoolInfo.programCount}
            cardLink={currentLink}
            schoolId={schoolInfo.id}
            filteredProgramCount={schoolInfo.filteredProgramCount}
            impressionGuid={schoolInfo.impressionGuid}
          />
        </div>
      </article>
    </ResultCardContext.Provider>
  );
}
