import React from 'react';
import PropTypes from 'prop-types';

function ResultCount({ count }) {
  const resultCountText =
    count === 1 ? `${count} school result` : `${count} school results`;
  return <span>{resultCountText}</span>;
}

ResultCount.propTypes = {
  count: PropTypes.number.isRequired,
};

export default ResultCount;
